@import '../../../../scss/theme-bootstrap';

.mpp-sort {
  border-bottom: solid 1px $color-black;
  &__container {
    padding: 10px;
    @include breakpoint($medium-up) {
      float: right;
      width: 25%;
    }
  }
}

.mpp-sort-menu {
  // use the triangle mixin to create a triangle twice as large as the default
  // for select boxes:
  .selectBox-arrow {
    @include triangle(16px, $color-black, down);
  }
  &.selectBox-menuShowing .selectBox-arrow {
    @include triangle(16px, $color-black, up);
    margin-top: -12px;
  }
}

// selectbox falling behind header text fix
.mpp-sort-menu-selectBox-dropdown-menu {
  z-index: 2;
}
